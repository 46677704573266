import { useRoute, navigateTo } from "nuxt/app";
import { watch, onMounted } from "vue";

export function useAnchorFunction(hash, fn) {
  const route = useRoute();

  onMounted(() => {
    if (route.hash === hash) {
      fn();
      navigateTo(route.path, { replace: true });
    }
  });

  watch(
    () => [route.path, route.hash],
    ([toPath], [fromPath]) => {
      if (route.hash === hash) {
        fn();
        const currentScroll = window.scrollY;
        navigateTo(route.path, { replace: true });
        if (toPath === fromPath) {
          setTimeout(() => window.scrollTo({ top: currentScroll }));
        }
      }
    }
  );
}
